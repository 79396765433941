import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import '../styles/_app.css'


const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}images/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}images/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}images/favicon-16x16.png`}
          sizes="16x16"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}images/apple-touch-icon.jpg`}
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What to expect?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Maintaining a healthy HIV status for yourself and your partners involves checking and knowing your own status! We’ve made this possible no matter where you are in the state of New Mexico. A free, 20 minute OraQuick HIV test kit is available to ship to your address for your use in the privacy of your own home. This is the same oral HIV test used in many clinics and testing centers across the country. To perform the test, you’ll only need to be able to wipe a testing swab along the gumlines in your mouth.<br>No blood and no sending samples to a lab.</p>"
                  }
                },
                {
                  "@type": "Question",
                  "name": "I finished my test, now what?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Congratulations for taking charge of knowing your status and being in control of your health! Claim your $15 gift card. Be ready to take/upload a picture of your test and have the secret code you were assigned. Enter the code in the field below and click submit to start.</p>"
                  }
                },
                {
                  "@type": "Question",
                  "name": "Find a local testing site?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Prefer to go somewhere in-person to be tested? Visit <a href='https://nmhivguide.org/'>this link</a> for an interactive search.</p>"
                    }
                },
                {
                  "@type": "Question",
                  "name": "I come back as reactive?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Then we help you get started with a confirmatory test and into care.</p>"
                  }
                },
                {
                  "@type": "Question",
                  "name": "I come back as negative?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Then we talk with you about PrEP or other ways to maintain your status.</p>"
                  }
                },
                {
                  "@type": "Question",
                  "name": "I currently have HIV. Will this test tell me my viral load?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>No, this test can only detect if there is evidence of HIV in the body.</p>"
                  }
                },
                {
                  "@type": "Question",
                  "name": "I was exposed to HIV through a method known to transmit the virus, but it was less than 30 days ago?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>This test can only detect evidence of HIV that has been in the body for 30 days or more. This is called a ‘window period’. <a href='tel:1-800-669-8378'>Call us</a> so we can discuss your options.</p>"
                  }
                },
                {
                  "@type": "Question",
                  "name": "I received my test and I’m too scared to begin?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Give us <a href='tel:1-800-669-8378'>a call</a> so we can answer your concerns and help ease the anxiety you may have. Having the test in your hand and outside of the shipping box may also give yourself more confidence. You are in control of your body and your health.</p>"
                  }
                },
                {
                  "@type": "Question",
                  "name": "I changed my mind? I really don’t want this test anymore.",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Don’t forget, there was a reason you wanted to take the test initially: to take charge of your health, know your status, and ensure that you are going to be the healthiest 'you' possible! However, if you are sure that you won’t take the test, please <a href='tel:1-800-669-8378'>call us</a> to learn how to dispose of the test properly.</p>"
                  }
                }
              ]
            }
          `}
        </script>
      </Helmet>
      {children}
      <Footer />
    </div>
  )
}

export default TemplateWrapper
