import React from 'react'
import { Link } from 'gatsby'

import Logo from '../../assets/betestednmlogo.svg'
import Facebook from '../../assets/social/facebook.svg'
import Instagram from '../../assets/social/instagram.svg'
import Twitter from '../../assets/social/twitter.svg'

import styles from "./index.module.css";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className={styles.Footer}>
        <div className={styles.Footer__wrapper}>
          <Logo
            alt="betestednmlogo"
            style={{ width: '14rem', height: '10rem' }}
          />
          {/* <p>{`© ${new Date().getFullYear()} Alianza`}</p>
          <div className={styles.Footer__social}>
            <a title="facebook" href="https://facebook.com">
              <Facebook
                alt="Facebook"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
            <a title="twitter" href="https://twitter.com">
              <Twitter
                alt="Twitter"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
            <a title="instagram" href="https://instagram.com">
              <Instagram
                alt="Instagram"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
          </div> */}
        </div>
      </footer>
    )
  }
}

export default Footer
